import StatusChip, { StatusType } from "Shared/components/chips/status-chip";
import Avatar from "Shared/components/media/avatar";
import AppConfig from "config";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import { FC } from "react";

interface MemberViewProps {
  payment: any;
}
const PaymentView: FC<MemberViewProps> = ({ payment }) => {
  function getStatusType(data): StatusType {
    if (data?.status == "Completed") return "success";
    if (data?.status == "Failed") return "danger";
    if (data?.status === "Pending") return "warning";
  }

  return (
    <div className='space-y-6 mb-6'>
      <div className={" bg-white border rounded"}>
        <div className='border-b py-3 px-2'>
          <h2 className='text-base font-semibold leading-7 text-gray-900'>
            User Information
          </h2>
        </div>
        <div
          className={
            "grid grid-cols-4 mb-4  dark:bg-[#242A38] dark:border-gray-600 gap-x-3 gap-y-6 py-4 px-4 "
          }
        >
          <div className='row-span-4 items-center justify-center flex flex-col space-y-2'>
            <Avatar
              alt={payment?.createdBy?.fullName}
              src={payment?.createdBy?.profileImageUrl || ""}
              size='lg'
            />
            <span className='block text-sm font-light text-gray-700'>
              {payment?.createdByType}
            </span>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>Code</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {payment?.createdBy?.code || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>
              Full Name
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {payment?.createdBy?.fullName || "N/A"}
            </div>
          </div>
          <div className=''>
            <span className='block text-sm font-light text-gray-700'>
              Country
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {payment?.createdBy?.country || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Created At
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {moment(payment?.dateOfBirth).format(AppConfig.date.format)}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Email Address
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {payment?.createdBy?.emailAddress || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Phone Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {payment?.createdBy?.phoneNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Balance
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              GHS {numeral(payment?.createdBy?.balance || 0).format("0,0.00")}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Escrow Balance
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              GHS{" "}
              {numeral(payment?.createdBy?.escrowBalance || 0).format("0,0.00")}
            </div>
          </div>
        </div>
      </div>
      <div className={" bg-white border rounded"}>
        <div className='border-b py-3 px-2 flex items-center gap-x-5 '>
          <h2 className='text-base font-semibold leading-7 text-gray-900'>
            Withdrawal Information
          </h2>
          <StatusChip type={getStatusType(payment)} info={payment?.status} />
          {payment?.status == "Completed" && (
            <div className='flex items-center gap-x-2.5 '>
              <span className='block text-sm font-light text-gray-700'>
                Paid At:
              </span>
              <div className=' sm:text-sm'>
                {moment(payment?.paidAt).format("DD/MM/YYYY hh:mm A")}
              </div>
            </div>
          )}
        </div>
        <div
          className={
            "grid grid-cols-4 mb-4  dark:bg-[#242A38] dark:border-gray-600 gap-x-3 gap-y-6 py-4 px-4 "
          }
        >
          <div>
            <span className='block text-sm font-light text-gray-700'>Code</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {payment?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Reference
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {payment?.reference || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>Date</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {moment(payment?.createdAt).format(AppConfig.date.format)}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Amount
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              GHS {numeral(payment?.amount || 0).format("0,0.00")}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Payment Channel
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {payment?.paymentMethod || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Payment Account
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {payment?.paymentAccount
                ? payment?.paymentMethod == "Card"
                  ? `Card Ending ${payment?.paymentAccount}`
                  : payment?.paymentAccount
                : "N/A"}{" "}
            </div>
          </div>
          <div className='col-span-2'>
            <span className='block text-sm font-light text-gray-700'>
              Withdrawal Notes
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {payment?.notes || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentView;
