import { FC, Fragment, useCallback, useEffect } from "react";
import { INavSection, NavSections } from "./data/navItems";
import SidebarNavItem from "./components/SidebarNavItem";
// import Divider from "../../../components/seperators/divider";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import useCookies from "../../hooks/cookies";
import { Menu, Transition } from "@headlessui/react";
import { wrapClick } from "../../utils/ui";

import { showToast } from "../../utils/alert";
import Logo from "Shared/components/brand/logo";
import { isValidJSON } from "Shared/utils/data-structures";
const Sidebar: FC = () => {
  const [user, setUser] = useCookies("user");
  const currentRoute = useLocation().pathname;
  const parsedUser = isValidJSON(user) ? JSON.parse(user) : {};

  return (
    <div className='w-full flex flex-col h-full overflow-auto items-stretch z-20'>
      {/* LOGO Area */}
      <div className='px-8 py-5 w-full h-20  relative'>
        <Logo />
      </div>
      {/* NAV Area */}
      <div className='pl-6 mt-2'>
        <ul className='py-2 grid grid-cols-1 mt-10 gap-y-1'>
          {NavSections.map((section, index) => (
            <li>
              <div className={"mt-4"}>
                <span className={"text-xs pl-5 text-gray-400"}>
                  {section.title}
                </span>
              </div>
              <ul>
                <Fragment key={section.title}>
                  {section.items.map((item) => (
                    <SidebarNavItem
                      icon={item.icon}
                      label={item.label}
                      to={item.to}
                      activeRoutes={item?.activeRoutes}
                      notificationType={item.notificationType}
                      notificationsCount={item.notificationsCount}
                    />
                  ))}
                </Fragment>
              </ul>
            </li>
          ))}
        </ul>
      </div>
      <div className='flex-1'></div>
    </div>
  );
};

export default Sidebar;
