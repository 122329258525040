import { Navigate, RouteObject } from "react-router-dom";
import Register from "Modules/Auth/Register";
import AppLayout from "Shared/layout";
import LoginPage from "Modules/Auth/Login";
import PaymentsListPage from "Modules/Payments";
import DashboardPage from "Modules/Dashboard";
import ViewPaymentPage from "Modules/Payments/view";

const routes = (isAuth): RouteObject[] => [
  {
    path: "/*",
    element: !isAuth ? <Navigate to={"/login"} /> : <AppLayout />,
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: "payments",
        element: <PaymentsListPage />,
      },
      {
        path: "payments/:id",
        element: <ViewPaymentPage />,
      },
    ],
  },
  // {
  //   path: "/register",
  //   element: <Register />,
  // },

  {
    path: "/login",
    element: isAuth ? <Navigate to={"/app"} /> : <LoginPage />,
  },
  // {
  //   path: "/forgot-password",
  //   element: isAuth ? <Navigate to={"/app"} /> : <ForgotPassword />,
  // },
  // {
  //   path: "/register",
  //   element: isAuth ? <Navigate to={"/app"} /> : <Register />,
  // },
  // {
  //   path: "/test",
  //   element: <MemberFormPrintable />,
  // },
  {
    path: "*",
    element: <Navigate to={isAuth ? "/" : "/login"} />,
  },
];

export default routes;
