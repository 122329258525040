import { FC } from "react";

interface TableEmptyComponentProps {
  title?: string;
}

const TableEmptyComponent: FC<TableEmptyComponentProps> = ({ title }) => {
  return (
    <div className='min-h-[300px] items-center justify-center flex'>
      <div className='text-center'>
        <svg
          className='mx-auto h-12 w-12 text-gray-500 dark:text-gray-300'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
          aria-hidden='true'
        >
          <path
            vectorEffect='non-scaling-stroke'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
          />
        </svg>
        <h3 className='mt-2 text-sm font-medium text-info-500 dark:text-gray-100'>
          No {title || "documents"}
        </h3>
        <p className='mt-1 text-sm text-gray-500 dark:text-gray-400'>
          Withdrawal requests will appear here .
        </p>
        {/* <div className="mt-6">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            New Project
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default TableEmptyComponent;
