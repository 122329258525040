import { FC } from "react";
import LoadingIcon from "../icons/loading-icon";
import { classNames } from "Shared/utils/ui";
import { LogoIconImage } from "assets";
interface LogoProps {
  className?: string;
  color?: string;
  isCollapsed?: boolean;
}

const Logo: FC<LogoProps> = ({ className, isCollapsed, color }) => {
  return (
    <div className='flex gap-x-2 items-center'>
      <img className={"h-14"} src={LogoIconImage} />
      {!isCollapsed && (
        <h4 className={classNames("text-[#222222] text-lg font-medium", color)}>
          Test Universal
        </h4>
      )}
    </div>
  );
};

export default Logo;
