import { useMutation } from "@tanstack/react-query";
import YesNoDialog from "Shared/components/overlays/yes-no-dialog";
import useUrlState from "Shared/hooks/use-url-state";
import { showToast } from "Shared/utils/alert";
import { formatAndShowAxiosError } from "Shared/utils/errors";
import { doCompletePayment, doFailPayment } from "./duck/fetch";
import { useParams } from "react-router-dom";
import StatusChip from "Shared/components/chips/status-chip";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export default function FailPaymentContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const {id}= useParams()
  const mutation = useMutation({
    mutationFn: () =>
      doFailPayment({
        id: id,
        values: {},
      }),
    onSuccess: (response) => {
      showToast({
        type: "success",
        title: "Withdrawal Successfully Canceled",
      });
      refetch?.();
      setOpen(false);
    },
    onError: (error) =>
      formatAndShowAxiosError(error || "Could not cancel withdrawal"),
  });

  return (
    <YesNoDialog
      open={open}
      setOpen={setOpen}
      title={"Cancel withdrawal?"}
      onYesClicked={mutation.mutate}
      yesColor='bg-red-500'
      yesLoading={mutation.isPending}
      onNoTapped={() => setOpen(false)}
    >
      <div className='py-3'>
        <span className="block">
          Are you sure you want to mark payment as{" "}
          <span className='text-red-500'>failed</span> ?
        </span>

        <div className='rounded-md bg-yellow-50 px-2 py-4 w-full mt-4'>
          <div className='flex justify-start'>
            <div className='flex-shrink-0'>
              <ExclamationTriangleIcon
                className='h-5 w-5 text-yellow-400'
                aria-hidden='true'
              />
            </div>
            <div className='ml-3'>
              <div className=' text-sm text-yellow-700'>
                <p>
                  Amount will be returned to users balance
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </YesNoDialog>
  );
}
