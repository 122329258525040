import { FC, useEffect, Fragment } from "react";
import {
  ChevronDownIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import { ChartPieIcon, ListBulletIcon } from "@heroicons/react/24/outline";

import { Menu, Transition } from "@headlessui/react";
import _ from "lodash";
import DayPicker from "./day-picker";
import QuarterPicker from "./quarter-picker";
import MonthPicker from "./month-picker";
import WeekPicker from "./week-picker";
import CustomPicker from "./custom-picker";
import { Outlet, Link, useSearchParams } from "react-router-dom";
import useUrlState from "Shared/hooks/use-url-state";
import { classNames } from "Shared/utils/ui";

const views = ["all-time", "custom"] as const;

const CalendarPicker: FC<{
  label?: string;
  id?: string;
  required?: boolean;
  // isFilter?: boolean;
}> = ({ id, required, label }) => {
  const [currentView, setCurrentView] = useUrlState<any>("view");
  const [fromDate, setFromDate] = useUrlState<any>("fromDate");
  const [toDate, setToDate] = useUrlState<any>("toDate");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!currentView) {
      setCurrentView("all-time");
    } else if (currentView === "all-time") {
      if (fromDate !== undefined || toDate !== undefined) {
        searchParams.delete("fromDate");
        searchParams.delete("toDate");
        setSearchParams(searchParams);
      }
    }
    // eslint-disable-next-line
  }, [currentView, setCurrentView]);
  return (
    <div className='h-full flex '>
      <label
        htmlFor={id}
        className={classNames(
          "w-2/5 bg-gray-200 dark:bg-[#242A38] border-l border-t border-b border-gray-300 flex items-center text-sm  px-2"
        )}
      >
        {label} {required ? "*" : ""}
      </label>
      <div className='flex items-center flex-1'>
        <div className='hidden  md:flex md:items-center w-full'>
          <Menu as='div' className='relative '>
            <Menu.Button
              type='button'
              className='flex items-center  border  bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50'
            >
              {_.capitalize(currentView)}
              <ChevronDownIcon
                className='ml-2 h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute z-10 right-0 mt-3 w-36 origin-top-right overflow-hidden bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <div className='py-1'>
                  {views.map((view) => (
                    <Menu.Item key={view}>
                      {({ active }) => (
                        <Link
                          to={undefined}
                          type='button'
                          onClick={() => setCurrentView(view)}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          {_.startCase(view)} 
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          {currentView === "day" && <DayPicker />}
          {currentView === "week" && <WeekPicker />}
          {currentView === "month" && <MonthPicker />}
          {currentView === "quarter" && <QuarterPicker />}
          {currentView === "custom" && <CustomPicker />}
        </div>
        <Menu as='div' className='relative ml-6 md:hidden flex'>
          <Menu.Button className='-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500'>
            <span className='sr-only'>Open menu</span>
            <EllipsisHorizontalIcon className='h-5 w-5' aria-hidden='true' />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute right-0 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
              <div className='py-1'>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href='/#'
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Create event
                    </a>
                  )}
                </Menu.Item>
              </div>
              <div className='py-1'>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href='/#'
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Go to today
                    </a>
                  )}
                </Menu.Item>
              </div>
              <div className='py-1'>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href='/#'
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Day view
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href='/#'
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Week view
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href='/#'
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Month view
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href='/#'
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Year view
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default CalendarPicker;
