import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const INACTIVE_TIMEOUT_TIME = 20 * 60;
export default function CountDown() {
  const [secondsLeft, setSecondsLeft] = useState(INACTIVE_TIMEOUT_TIME);
  const location = useLocation();

  const formatTime = (number) => (number < 10 ? "0" : "") + number.toString();

  useEffect(() => {
    resetTime();
  }, [location]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSecondsLeft((previousSeconds) => {
        if (previousSeconds === 0) {
          //logout
          return 0;
        }
        return previousSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const resetTime = () => setSecondsLeft(INACTIVE_TIMEOUT_TIME);

  return (
    <div
      className={`flex items-center rounded bg-${
        secondsLeft <= Math.round(INACTIVE_TIMEOUT_TIME * 0.1)
          ? "red-100"
          : "[#007aff]/5"
      } text-${
        secondsLeft <= Math.round(INACTIVE_TIMEOUT_TIME * 0.1)
          ? "red-500"
          : "[#007aff]"
      } py-1 px-2 text-sm border border-${
        secondsLeft <= Math.round(INACTIVE_TIMEOUT_TIME * 0.1)
          ? "red-500"
          : "[#007aff]/30"
      }`}
    >
      <div className={" mx-1 py-0.5  rounded font-proximaBold"}>
        {formatTime(Math.floor(secondsLeft / 60))} :{" "}
        {formatTime(secondsLeft % 60)}
      </div>

      <div className={"px-2"}>|</div>

      <div
        onClick={resetTime}
        className={"text-xs cursor-pointer font-proximaBold"}
      >
        Reset
      </div>
    </div>
  );
}
