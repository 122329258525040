import { AxiosDataService } from "Shared/utils/axios";


export const doRegister = async (values: any) => {
    return AxiosDataService.post('/api/auth/register', values)
}


export const doLogin = async (values: any) => {
    return AxiosDataService.post('/api/auth/login-admin', values)
}
