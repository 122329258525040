import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ucFirstWord } from "Shared/utils/string";
import _ from "lodash";
import CountDown from "Shared/components/suspense/countdown";
import useCookies from "Shared/hooks/cookies";
import { isValidJSON } from "Shared/utils/data-structures";
import { clearAuth } from "Shared/utils/auth";
export default function Toolbar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [user, setUser] = useCookies("user");

  const parsedUser = isValidJSON(user) ? JSON.parse(user) : {};

  const [menuOpened, setMenuOpened] = useState(false);
  const actions = [
   
    {
      title: "Logout Session",
      onClick: () => {
        clearAuth()
      },
    },
  ];

  return (
    <div
      className={`flex justify-between pt-4 z-10 pb-3 sticky top-0 px-2 bg-white left-0 right-0 ${props.className}`}
    >
      <h3 className='text-xl font-firmaBold text-gray-700 dark:text-gray-50'>
        {ucFirstWord(_.capitalize(pathname.split("/")[1]).replaceAll("-", " "))}
      </h3>
      <div className={"flex items-center"}>
        <CountDown />

        {/*<NotificationIcon className={"fill-gray-100 mx-2 w-5 h-5"}/>*/}

        {/*<SettingsIcon onClick={() => {*/}
        {/*    navigate("/settings")*/}
        {/*}} className={"fill-gray-100 cursor-pointer mx-4  w-5 h-5"}/>*/}

        <div
          onClick={() => {
            setMenuOpened(!menuOpened);
          }}
          className='relative ml-8 border-2 text-blue-500 flex items-center
                                justify-center border-blue-500 dark:border-white
                                 rounded-full cursor-pointer h-10 w-10'
        >
          <span className={"text-sm dark:text-white font-medium"}>
            {parsedUser?.name &&
              parsedUser?.name
                .split(" ")
                .map((item) => item.charAt(0))
                .join("")}
          </span>

          <div
            className={`mt-4 absolute z-40 border 
                            overflow-hidden overflow-y-auto
                            rounded-lg max-h-44 bg-white 
                            mb-2 rounded -left-32 -right-2 top-4
                         ${menuOpened ? "block" : "hidden"}
                         `}
          >
            {actions.map((item, index) => {
              return (
                <div
                  key={index}
                  data-class={"allowed"}
                  onClick={item.onClick}
                  className='h-10 px-6 border-t flex text-gray-600
                            hover:bg-gray-50 text-xs cursor-pointer
                             items-center'
                >
                  {item.title}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
