interface INavItem {
  to: string;
  icon: any;
  label: string;
  notificationsCount?: number;
  notificationType?: "info" | "danger";
  activeRoutes?: string[];
}

export interface INavSection {
  title: string;
  items: INavItem[];
}
export const NavSections: INavSection[] = [
  {
    title: "Dashboard",
    items: [
      {
        to: "/",
        icon: "uil:apps",
        label: "Overview",
      },
    ],
  },

  {
    title: "Payments",
    items: [
      {
        to: "/payments",
        icon: "uil:money-withdrawal",
        label: "Withdrawal Requests",
      },
    ],
  },
];
