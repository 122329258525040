import { FC, isValidElement, ReactNode, useEffect } from "react";
import { ExportButton } from "./export-btn";
import TextInput from "../input/text-input";
import { useFormik } from "formik";
import { classNames, wrapClick } from "Shared/utils/ui";
import {
  ArrowPathIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import useUrlState from "Shared/hooks/use-url-state";
import _ from "lodash";

interface TableHeaderComponentProps {
  hideExportButtons?: boolean;
  isRefetching?: boolean;
  mini?:boolean
  onExportClicked?: (type: "excel" | "csv" | "pdf") => void;
  exportDownloading?: "excel" | "csv" | "pdf";
  refetch: () => void;
}
const TableHeaderComponent: FC<TableHeaderComponentProps> = ({
  hideExportButtons,
  exportDownloading,
  onExportClicked,
  isRefetching,
  refetch,
  mini
}) => {
  const [search, setSearch] = useUrlState<string>("search");
  const form = useFormik<any>({
    initialValues: {
      search: search,
    },
    onSubmit: async (values) => {
      setSearch(values.search);
    },
    onReset: () => {},
  });
  return (
    <div className='w-full'>
      <div className='flex flex-row justify-between'>
        {!hideExportButtons ? (
          <div className={"flex items-center gap-4"}>
            <ExportButton
              type={"excel"}
              exportDownloading={exportDownloading}
              onExportClicked={onExportClicked}
            />
            <ExportButton
              type={"csv"}
              exportDownloading={exportDownloading}
              onExportClicked={onExportClicked}
            />
            {/* <ExportButton
              type={"pdf"}
              exportDownloading={exportDownloading}
              onExportClicked={onExportClicked}
            /> */}
          </div>
        ) : (
          <div></div>
        )}
        <div className='flex items-center justify-center gap-x-3'>
          <div className='max-w-80'>
            <TextInput
              type='search'
              placeholder={"Search"}
              id='search'
              {...form}
              handleChange={(e) => {
                if (!e?.target?.value) {
                  setSearch(undefined);
                }
                form.handleChange(e);
              }}
            />
          </div>
          <div className='   bg-gray-100 dark:bg-gray-800 rounded-lg  flex items-center justify-center'>
            <button
              type='button'
              onClick={wrapClick(() => setSearch(form.values?.search))}
              className='bg-gray-50 border dark:bg-gray-800 hover:bg-white dark:hover:bg-gray-900  p-1.5 rounded-md shadow-sm text-gray-500 dark:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500'
            >
              <MagnifyingGlassIcon
                className={classNames(
                  "h-5 w-5"
                )}
                aria-hidden='true'
              />
              <span className='sr-only'>Refresh</span>
            </button>
          </div>
          <div className='   bg-gray-100 dark:bg-gray-800 rounded-lg  flex items-center justify-center'>
            <button
              type='button'
              onClick={wrapClick(refetch)}
              className='bg-gray-50 border dark:bg-gray-800 hover:bg-white dark:hover:bg-gray-900  p-1.5 rounded-md shadow-sm text-gray-500 dark:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500'
            >
              <ArrowPathIcon
                className={classNames(
                  isRefetching ? "animate-spin" : "animate-none",
                  "h-5 w-5"
                )}
                aria-hidden='true'
              />
              <span className='sr-only'>Refresh</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableHeaderComponent;
