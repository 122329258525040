import { AxiosDataService } from "Shared/utils/axios"
import { IGetFilter } from "data/index.types"

export const doCreatePayment = async (values: any) => {
    return AxiosDataService.post('/api/payments', values)
}




export const getPayments = async (filter: IGetFilter) => {
    return AxiosDataService.get(`/api/payments`, filter)
}



export const getPaymentsExportUrl = async (filter: IGetFilter) => {
    return AxiosDataService.get(`/api/payments/export?populate=createdBy`, filter)
}



export const getPayment = async (id: string, populate?: string | string[]) => {
    return AxiosDataService.get("/api/payments/" + id, {
        populate: populate || ["createdBy", "task"]

    })
}

export const getMiniStaffs = async () => {
    return AxiosDataService.get("/api/staff?select=name,id")
}



export const doCompletePayment = async (params: { id: string, values: any }) => {
    return AxiosDataService.post(`/api/payments/${params.id}/complete`, params.values)
}
export const doFailPayment = async (params: { id: string, values: any }) => {
    return AxiosDataService.post(`/api/payments/${params.id}/fail`, params.values)
}