import { FC } from "react";
import { Icon } from "@iconify/react";
import { Link, useLocation } from "react-router-dom";
import { classNames } from "Shared/utils/ui";

interface SidebarNavItemProps {
  icon: string;
  label: string;
  to: string;
  isCollapsed?: boolean;
  notificationsCount?: number;
  notificationType?: "info" | "danger";
  activeRoutes?: string[];
}
const SidebarNavItem: FC<SidebarNavItemProps> = ({
  icon,
  label,
  to,
  notificationsCount,
  notificationType,
  isCollapsed,
  activeRoutes = [],
}) => {
  const currentRoute = useLocation().pathname;
  const isActive = currentRoute === to || activeRoutes.includes(currentRoute);
  return (
    <li title={label}>
      <Link
        to={to}
        className={classNames(
          "py-1.5  hover:bg-gray-50 text-base flex   items-center  mr-5  rounded-lg",
          isActive ? "bg-[#E4EBFA] " : ""
        )}
      >
        <>
          <div
            className={classNames(
              "py-2 px-2 mx-2 rounded-lg",
              isActive ? "bg-blue-700" : ""
            )}
          >
            <Icon
              icon={icon}
              strokeWidth={5}
              className={classNames(
                "w-5 h-5",
                isActive ? " fill-none text-blue-200" : " fill-none"
              )}
            />
          </div>

          <h3 className='text-sm mx-2 dark:text-white '>{label} </h3>
          {/* {notificationsCount && (
            <div
              className={classNames(
                "rounded-full px-1 flex items-center justify-center  w-[20px] h-[20px] text-center text-white text-xs font-bold ",
                notificationsCount && notificationType === "info"
                  ? "bg-primary-500  "
                  : "bg-red-500 "
              )}
            >
              {notificationsCount}
            </div>
          )} */}
        </>
      </Link>
    </li>
  );
};

export default SidebarNavItem;
