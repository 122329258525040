import { useMutation } from "@tanstack/react-query";
import YesNoDialog from "Shared/components/overlays/yes-no-dialog";
import useUrlState from "Shared/hooks/use-url-state";
import { showToast } from "Shared/utils/alert";
import { formatAndShowAxiosError } from "Shared/utils/errors";
import { doCompletePayment } from "./duck/fetch";
import { useParams } from "react-router-dom";

export default function CompletePaymentContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const {id}= useParams()
  const mutation = useMutation({
    mutationFn: () =>
      doCompletePayment({
        id: id,
        values: {},
      }),
    onSuccess: (response) => {
      showToast({
        type: "success",
        title: "Payment Successfully Completed \n,  User will be notified",
      });
      refetch?.();
      setOpen(false);
    },
    onError: (error) =>
      formatAndShowAxiosError(error || "Could not complete payment"),
  });

  return (
    <YesNoDialog
      open={open}
      setOpen={setOpen}
      title={"Have you made payment?"}
      onYesClicked={mutation.mutate}
      yesLoading={mutation.isPending}
      onNoTapped={() => setOpen(false)}
    >
      <div className=' py-3'>
        <span>Are you sure you want to mark payment as completed</span>
      </div>
    </YesNoDialog>
  );
}
