import { FC } from "react";
import Sidebar from "./sidebar";
import { Outlet } from "react-router-dom";
import AppNavBar from "./nav";

const AppLayout: FC = () => {
  return (
    <>
      <div className='bg-gray-200  w-screen h-screen flex '>
        <aside
          className=' md:flex    md:flex-col  shadow bg-gray-50 sticky top-0 left-0'
          style={{
            transition: "all 300ms ease-in-out",
            width: "256px",
          }}
        >
          <Sidebar />
        </aside>
        <main className='flex flex-1 flex-col h-screen    overflow-y-scroll bg-gray-100 '>
          <nav className='top-0 sticky  border-b'>
            {" "}
            <AppNavBar />
          </nav>
          <div className='p-12'>
            <Outlet />
          </div>
        </main>
        <footer></footer>
      </div>
    </>
  );
};

export default AppLayout;
