import { FC, ReactNode } from "react";
import TableBodyComponent from "./table-body";
import TableEmptyComponent from "./table-empty";
import TableFooterComponent from "./table-footer";
import TableHeaderComponent from "./table-header";
import TableGridBodyComponent from "./table-grid-body";
import useUrlState from "Shared/hooks/use-url-state";
import { classNames } from "Shared/utils/ui";

interface TableComponentProps<TData = any> {
  title: string;
  data: {
    rows: TData[];
    total: number;
    page: number;
    pageSize: number;
    totalPages: number;
  };
  loading?: boolean;
  hideExportButtons?: boolean;
  renderLoader?: FC;
  refetch: () => void;
  mini?: boolean;
  isRefetching?: boolean;
  fields?: (string | FC<TData>)[];
  onExportClicked?: (type: "excel" | "csv" | "pdf") => void;
  exportDownloading?: "excel" | "csv" | "pdf";
  columns: (string | ReactNode)[];
  loaders?: (("action" | "single" | "double" | "avatar") | FC<TData>)[];
}

const TableComponent: FC<TableComponentProps> = ({
  title,
  data,
  loading,
  columns,
  fields,
  refetch,
  isRefetching,
  hideExportButtons,
  onExportClicked,
  exportDownloading,
  mini,
  loaders,
}) => {
  return (
    <div
      className={classNames(
        "dark:bg-[#242A38] bg-gray-50 dark:border-gray-500 border   ",

        mini ? "" : "rounded-lg mb-4 pt-2 "
      )}
    >
      <div className='space-y-6 flex-1 flex flex-col overflow-y-hidden min-w-full'>
        <div className='px-2'>
          {!mini && (
            <TableHeaderComponent
              mini={mini}
              refetch={refetch}
              isRefetching={isRefetching}
              onExportClicked={onExportClicked}
              hideExportButtons={hideExportButtons}
              exportDownloading={exportDownloading}
            />
          )}
        </div>
        {!loading && (data?.total || 0) === 0 ? (
          <TableEmptyComponent title={title} />
        ) : (
          <>
            <div className='px-2'>
              <TableBodyComponent
                data={data}
                loading={loading}
                columns={columns}
                loaders={loaders}
                fields={fields}
              />
            </div>
            <div className=''>
              <TableFooterComponent data={data} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TableComponent;
