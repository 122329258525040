import { FC } from "react";
import { ButtonProps } from "./index.types";
import { Icon } from "@iconify/react";
import LoadingIcon from "../icons/loading-icon";
import { classNames } from "Shared/utils/ui";

// const sizesMap = {
//   xs: "px-3 py-2 text-sm",
//   sm: "px-4 py-2 text-sm",
//   md: "py-2.5  px-4 text-base",
//   lg: "py-3 px-6 text-lg",
// };
// const loaderSizesMap = {
//   xs: 12.5,
//   sm: 15,
//   md: 30,
//   lg: 40,
// };
const PrimaryButton: FC<
  ButtonProps & {
    // size?: "sm" | "md" | "lg";
    iconPosition?: "left" | "right";
    className?: string;
    loading?: boolean;
    type?: "button" | "submit" | "reset";
    icon?: any;
  }
> = ({
  text,
  onClick,
  className,
  icon,
  iconPosition,
  type = "button",
  loading,
  hideTitle,
  // size = "md",
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      title={text}
      disabled={loading || disabled}
      className={classNames(
        // sizesMap[size],
        "bg-primary-500 text-white  rounded-md flex  gap-x-2 items-center justify-center hover:bg-primary-600 disabled:bg-opacity-80 disabled:cursor-not-allowed text-xs border py-3 px-10 font-proximaBold",
        iconPosition === "right" ? "flex-row-reverse" : "flex-row",
        className
      )}
    >
      {/* Ratio : cssHeight 50 : height 10 : width : 3.33  */}
      {loading ? (
        // <FadeLoader
        //   color='#fff'
        //   speedMultiplier={1}
        //   margin={-5}
        //   width={3}
        //   height={5}
        //   cssOverride={{
        //     height: 24,
        //   }}
        // />
        <LoadingIcon
          className={`animate-spin h-5 w-5 mx-2 fill-white ${
            loading ? "block" : "hidden"
          }`}
        />
      ) : (
        <>
          {icon && <Icon icon={icon} color='#fff' className='w-4 h-4' />}
          {!hideTitle && text}
        </>
      )}
    </button>
  );
};

export default PrimaryButton;
