import TextInput from "Shared/components/input/text-input";
import { showToast } from "Shared/utils/alert";
import { isAxiosError } from "axios";
import { useFormik } from "formik";
import { FC } from "react";
import { ILogin, LoginSchema } from "../schema";
import { useMutation } from "@tanstack/react-query";
import { doLogin } from "../duck/fetch";
import PrimaryButton from "Shared/components/buttons/primary-button";
import { LoginCoverImage, LogoIconImage } from "assets";
import { setMe, setToken } from "Shared/utils/auth";
import { formatAndShowAxiosError } from "Shared/utils/errors";

const LoginPage: FC = () => {
  const form = useFormik<ILogin>({
    initialValues: {
      emailAddress: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      mutation.mutate({ ...values, username: values.emailAddress });
    },
    onReset: () => {},
  });
  const mutation = useMutation({
    mutationFn: doLogin,
    onSuccess: (response) => {
      showToast({
        type: "success",
        title: "Login Successful",
      });

      setMe(response.data?.user);
      setToken(response.data?.token);
      setTimeout(() => {
        window.location.replace("/");
      }, 1000);
    },
    onError: (error) => formatAndShowAxiosError(error),
  });

  return (
    <div>
      <img
        src={LoginCoverImage}
        className='h-screen w-screen object-cover object-top'
      />
      <div
        className='absolute top-0
                    bottom-0 left-0 right-0
                    flex'
      >
        <div className='relative drop-shadow-md w-1/2 bg-gradient-to-r from-black'>
          <div
            className={
              "w-1/2 absolute bottom-0 top-0 hidden lg:flex flex-col justify-center mx-10"
            }
          >
            <div className='flex flex-col absolute text-white bottom-0 mb-2'>
              <span className={"text-xs"}>v 1.1</span>
            </div>
            <div className='flex gap-x-2 items-center'>
              <img className={"w-20"} src={LogoIconImage} />
              <h4 className={" text-2xl text-white font-medium"}>
                Test Universal
              </h4>
            </div>
            <div
              className='text-left mb-28 mt-4 text-3xl
                            text-gray-50
                            font-firmaBold'
            >
              <h3>Finance Management Portal</h3>
            </div>

            <div className={""}>
              {/*<img className={"w-44"}*/}
              {/*     src={"https://aywma.gov.gh/img/logo.png"}*/}
              {/*/>*/}
            </div>
          </div>
        </div>

        <div className={"w-full lg:w-1/2 flex items-center justify-center"}>
          <div className='flex flex-col w-full mx-10 sm:mx-20 md:mx-40 lg:mr-10 ml-10'>
            <form
              onSubmit={form.handleSubmit}
              className={
                "p-8 border rounded grid  gap-y-4 grid-cols-1 bg-white"
              }
            >
              <div className={"font-firmaBold text-3xl text-gray-500"}>
                Login.
              </div>

              <div>
                <TextInput
                  id='emailAddress'
                  label='Email Address'
                  type='text'
                  required
                  placeholder='e.g. developer@company.com'
                  {...form}
                />
              </div>
              <div>
                <TextInput
                  id='password'
                  label='Password'
                  type='password'
                  required
                  placeholder='Password'
                  {...form}
                />
              </div>

              <div>
                <span className='cursor-pointer text-blue-900 text-xs font-firmaBold'>
                  Forgot Password ?
                </span>
              </div>

              <div className='flex justify-between'>
                <div></div>
                <PrimaryButton
                  text='Sign in'
                  loading={mutation.isPending}
                  type='submit'
                />
              </div>
            </form>

            <div className={"mt-3"}>
              <span className={"font-firma  text-white text-xs"}>
                Can't Login ?{" "}
                <a className={"text-white cursor-pointer underline"}>
                  Contact Admin
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
