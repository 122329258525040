import { useFormik } from "formik";
import { FC, useEffect } from "react";
import { showToast } from "Shared/utils/alert";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AxiosError, isAxiosError } from "axios";
import PrimaryButton from "Shared/components/buttons/primary-button";
import _ from "lodash";
import { formatAndShowAxiosError } from "Shared/utils/errors";
import { wrapClick } from "Shared/utils/ui";
import Loader from "Shared/components/suspense/loaders";
import { getPayment } from "./duck/fetch";
import useUrlState from "Shared/hooks/use-url-state";
import PaymentView from "./components/cards/payment-view";
import CompletePaymentContainer from "./complete";
import FailPaymentContainer from "./fail";

const ViewPaymentPage: FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modal, setModal] = useUrlState("modal");

  const query = useQuery({
    queryKey: ["payment", id],
    queryFn: () => getPayment(id),
    throwOnError: (error: AxiosError | any) => {
      formatAndShowAxiosError(error);
      return false;
    },
  });

  return (
    <div>
      {query.isLoading ? (
        <div className='mt-10'>
          <Loader />
        </div>
      ) : (
        <div>
          <PaymentView payment={query?.data?.data} />
          <div className={"flex justify-end gap-x-3 pb-4 px-4 "}>
            {query?.data?.data?.status == "Pending" && (
              <>
                {" "}
                <PrimaryButton
                  text='Mark as Completed'
                  icon={"ic:twotone-check-circle-outline"}
                  onClick={() => setModal("add")}
                />
                <PrimaryButton
                  className='bg-red-500'
                  text='Cancel Withdrawal'
                  icon={"ic:outline-cancel"}
                  onClick={() => setModal("delete")}
                />
              </>
            )}
          </div>
        </div>
      )}
      {query?.data?.data?.status == "Pending" && (
        <>
          <CompletePaymentContainer
            open={modal === "add"}
            setOpen={(val: boolean) => setModal(val ? "add" : undefined)}
            refetch={query.refetch}
          />
          <FailPaymentContainer
            open={modal === "delete"}
            setOpen={(val: boolean) => setModal(val ? "delete" : undefined)}
            refetch={query.refetch}
          />
        </>
      )}
    </div>
  );
};

export default ViewPaymentPage;
