import * as yup from 'yup'
export interface ILogin {
    emailAddress: string
    password: string
}

export const LoginSchema = yup.object().shape({
    emailAddress: yup.string().email("Must Be a Valid Email").required("Email is required"),
    password: yup
        .string()
        .min(6, "Password must be more than 6 characters")
        .required("Password is required")
        // .matches(
        //     /^(?=.*[a-z])/,
        //     "Must Contain at least One Lowercase Character"
        // )
        // .matches(
        //     /^(?=.*[A-Z])/,
        //     "Must Contain at least One Uppercase Character"
        // )
        // .matches(/^(?=.*[0-9])/, "Must Contain at least One Number")
        // .matches(
        //     /^(?=.*[!@#$%^&*\\|/{}()<>:;[\]_\\-\\=?])/,
        //     "Must Contain at least One special case Character"
        // ),
})